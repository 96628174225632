<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('company.companyActions') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 class="primary--text text--darken-1 font-weight-regular">{{ companyId ? $t('company.viewCompany')  : $t('company.addCompany') }}</h5>
            <v-spacer />
            <v-btn
              v-if="!companyId"
              color="primary"
              :loading="loading"
              small
              :disabled="!valid"
              @click="saveRole"
            >
              {{ $t('company.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5 pa-0" v-if="!companyId">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-stepper
                    class="pb-3"
                    v-model="e6"
                    vertical
                    non-linear
                    flat
                  >
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 1"
                      editable
                      step="1"
                      :rules="[value => !!model.name, value => model.name && model.name.length >= 5, value => !!model.password, value => model.password && model.password.length >= 6, value => !!model.email, value => /.+@.+\..+/.test(model.email), value => !!model.authorized_dealer_no, value => !/^\s*$/.test(model.authorized_dealer_no)]"
                    >
                      <h6 class="primary--text font-weight-regular">{{$t('company.basicDetails')}}</h6>
                    </v-stepper-step>

                    <v-stepper-content step="1" class="ml-7 mb-auto pb-0 mr-3 py-2 px-4">
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            v-model.trim="model.name"
                            :label="$t('company.companyName')"
                            :rules="companyValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.name_he"
                            :label="$t('company.companyHeName')"
                            :rules="companyHeValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model.trim="model.authorized_dealer_no"
                            :label="$t('company.field.authorizedDealerNo')"
                            :rules="authorizedNoValid"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="model.email"
                            :label="$t('company.field.email')"
                            :rules="emailValid"
                            required
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="model.password"
                            :label="$t('company.field.password')"
                            :rules="passwordValid"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text class="text-h5 pa-0" v-else>
            <FormSkeleton v-if="companyId && Object.keys(companyById).length <= 0"/>
            <v-form
              v-else
            >
              <v-row>
                <v-col cols="12">
                  <v-stepper
                    class="pb-0"
                    vertical
                    non-linear
                    flat
                  >
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      complete
                      complete-icon="mdi-hand-pointing-right"
                      step="1"
                    >
                      <h6 class="primary--text font-weight-regular">{{$t('company.basicDetails')}}</h6>
                    </v-stepper-step>

                    <v-stepper-content step="1" class="ml-7 mb-auto pb-0 mr-3 py-2 px-4">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.companyName')}}:</span>
                            <span class="ms-2 primary--text">{{model.name}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.companyHeName')}}:</span>
                            <span class="ms-2 primary--text">{{model.name_he}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.phones')}}:</span>
                            <span class="ms-2 primary--text">{{model.phone}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.email')}}:</span>
                            <span class="ms-2 primary--text">{{model.email}}</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.addressLine1')}}:</span>
                            <span class="ms-2 primary--text">{{model.address_line_1}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.addressLine2')}}:</span>
                            <span class="ms-2 primary--text">{{model.address_line_2}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.addressLine3')}}:</span>
                            <span class="ms-2 primary--text">{{model.address_line_3}}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      complete
                      complete-icon="mdi-hand-pointing-right"
                      step="1"
                    >
                      <h6 class="primary--text font-weight-regular">{{$t('company.otherDetails')}}</h6>
                    </v-stepper-step>

                    <v-stepper-content step="1" class="ml-7 mb-auto pb-0 mr-3 py-2 px-4">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.authorizedDealerNo')}}:</span>
                            <span class="ms-2 primary--text">{{model.authorized_dealer_no}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.vatRegNo')}}:</span>
                            <span class="ms-2 primary--text">{{model.vat_reg_no}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.occasionalCustomerNo')}}:</span>
                            <span class="ms-2 primary--text">{{model.occasionalCustomerNo}}</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.activeManager')}}:</span>
                            <span class="ms-2 primary--text">{{model.active_manager}}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      complete
                      complete-icon="mdi-hand-pointing-right"
                      step="1"
                    >
                      <h6 class="primary--text font-weight-regular">{{$t('company.displayText')}}</h6>
                    </v-stepper-step>

                    <v-stepper-content step="1" class="ml-7 mb-auto pb-0 mr-3 py-2 px-4">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAttheBeginningOfaProposal')}}:</span><br/>
                            <span class="primary--text">{{model.beginning_proposal_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAttheEndOfProposal')}}:</span><br/>
                            <span class="primary--text">{{model.end_proposal_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAttheBeginningoforder')}}:</span><br/>
                            <span class="primary--text">{{model.beginning_order_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAttheEndoforder')}}:</span><br/>
                            <span class="primary--text">{{model.end_order_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.beginningReceiptText')}}:</span><br/>
                            <span class="primary--text">{{model.beginning_receipt_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.endReceiptText')}}:</span><br/>
                            <span class="primary--text">{{model.end_receipt_text}}</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAtTheBeginningOfTheDelivery')}}:</span><br/>
                            <span class="primary--text">{{model.beginning_delivery_note}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAtTheEndOfADeliveryNote')}}:</span><br/>
                            <span class="primary--text">{{model.end_delivery_note}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAtTheBeginningOfAnInvoice')}}:</span><br/>
                            <span class="primary--text">{{model.beginning_invoice_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textAtTheEndOfAnInvoice')}}:</span><br/>
                            <span class="primary--text">{{model.end_invoice_text}}</span>
                          </div>
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.textForDueDate')}}:</span><br/>
                            <span class="primary--text">{{model.due_date_text}}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      complete
                      complete-icon="mdi-hand-pointing-right"
                      step="1"
                    >
                      <h6 class="primary--text font-weight-regular">{{$t('company.printingAndPricing')}}</h6>
                    </v-stepper-step>

                    <v-stepper-content step="1" class="ml-7 mb-auto pb-0 mr-3 py-2 px-4">
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            :dense="true"
                            readonly
                            v-model="model.print_due_date"
                            :label="$t('company.field.printingDueDate')"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            :dense="true"
                            readonly
                            v-model="model.total_rounding"
                            :label="$t('company.field.totalRounding')"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            :dense="true"
                            readonly
                            v-model="model.print_balance_invoice"
                            :label="$t('company.field.balancePrintingOnInvoice')"
                          />
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      complete
                      complete-icon="mdi-hand-pointing-right"
                      step="1"
                    >
                      <h6 class="primary--text font-weight-regular">{{$t('company.companyLogo')}}</h6>
                    </v-stepper-step>
                    <v-stepper-content step="1" class="ml-7 mb-auto mr-3 py-2 px-4">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.topLogo')}}:</span><br/>
                            <span class="primary--text">
                              <v-img class="mt-2" :src="topLogoUrl" width="200px"/>  
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="text-body-2 mb-2">
                            <span>{{$t('company.field.bottomLogo')}}:</span><br/>
                            <span class="primary--text">
                              <v-img class="mt-2" :src="bottomLogoUrl" width="200px"/>
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FormSkeleton from '@/components/skeleton/FormSkeleton';
import Alert from "@/components/Alert"
import {changedFormData} from '@/utils';

export default {
  name: 'CompanyAction',
  components: {
    FormSkeleton,
    Alert
  },
  data() {
    return {
      message: '',
      alert: false,
      loading: false,
      valid: false,
      model: {},
      originModel: {},
      e6: 1,
      topLogoUrl: '',
      bottomLogoUrl: '',
      showPassword: false
    }
  },
  computed: {
    companyValid() {
      return [
        v => !!v || this.$t('formRules.companyRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.companyRequired'),
        v => (v && v.length >= 5) || this.$t('formRules.companyValidMessage'),
        v => !v || (v && v.length <= 64) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('company.companyName'), type: this.$t('common.less'), size: 64 }),
      ];
    },
    companyHeValid() {
      return [
        v => !v || !/^\s*$/.test(v) || this.$t('formRules.companyRequired'),
        v => !v || (v && v.length >= 5) || this.$t('formRules.companyValidMessage'),
        v => !v || (v && v.length <= 64) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('company.companyHeName'), type: this.$t('common.less'), size: 64 }),
      ];
    },
    authorizedNoValid() {
      return [
        v => !!v || this.$t('formRules.authorizedNoRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.authorizedNoRequired'),
        v => !v || (v && v.length <= 35) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('company.field.authorizedDealerNo'), type: this.$t('common.less'), size: 35 })
      ];
    },
    emailValid() {
      return [
        v => !!v || this.$t('formRules.emailRequired') ,
        v => /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage'),
        v => !v || (v && v.length <= 64) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('company.field.email'), type: this.$t('common.less'), size: 64 }),
      ];
    },
    passwordValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
        v => (v && (v.length >= 6 && v.length <= 20)) || this.$t('formRules.passwordValidMessage'),
      ];
    },
    companyId() {
      return this.$route.params.id
    },
    ...mapGetters({
      companyById: 'companyById'
    }),
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.$store.dispatch('GetCompanyById', this.$route.params.id);
    }
  },
  watch: {
    e6() {
      if(this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    companyById() {
      if (Object.keys(this.companyById).length > 0) {
        this.model = Object.assign({}, this.model, this.companyById)
        if(this.model.topLogo) {
          this.topLogoUrl = this.model.topLogo.file_path;
        }
        if(this.model.bottomLogo) {
          this.bottomLogoUrl = this.model.bottomLogo.file_path;
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    }
  },
  beforeDestroy() {
    this.$route.params.id ? this.$refs.form.reset() : '';
    this.model = {},
    this.$store.commit('SET_COMPANY_BY_ID', {});
  },
  methods: {
    async saveRole() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let data;
        if(this.$route.params.id) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store.dispatch('EditCompany', {company_id: this.$route.params.id, company: saveData}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
        } else {
          data = await this.$store.dispatch('SetCompany', this.model).catch((e) => {
            this.loading = false;
            console.log(e)
          });
        }
        if(data) {
          this.loading = false;
          this.$refs.form.reset();
          this.$router.push('/companies');
        } else {
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    }
  }
}
</script>
